import { Link } from "react-router-dom"
import cl from './PoweredByBrsys.module.scss'


const PoweredByBrsys = ({classNames}) => {
  return (
    <Link className={`${classNames} ${cl.link} link link-brsys`} to="http://brsys.kz/" target={'_blank'}>
      <span>Powered by </span>
      <svg
        width="99"
        height="17"
        viewBox="0 0 99 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M30.8989 11.1776H36.5127C37.4682 11.1347 39.7137 10.5607 41.0515 8.60714C41.8894 7.38353 42.7711 6.66751 43.4499 6.28221C44.0417 5.94633 44.7376 5.9082 45.418 5.9082H51.9207"
          strokeWidth="1.91107"
        />
        <path
          d="M76.5664 11.1776H82.1802C83.1357 11.1347 85.3812 10.5607 86.719 8.60714C87.5569 7.38353 88.4385 6.66751 89.1174 6.28221C89.7091 5.94633 90.4051 5.9082 91.0855 5.9082H97.5882"
          strokeWidth="1.91107"
        />
        <path
          d="M1.27734 3.67285V8.91244C1.3838 9.66752 2.02783 11.1777 3.75234 11.1777C5.47686 11.1777 10.4322 11.1777 13.8919 11.1777C15.1693 11.1777 16.7661 10.6827 16.7661 8.70269C16.7661 6.72269 15.4088 5.98817 13.8919 5.98817H4.15154"
          strokeWidth="1.91613"
        />
        <path
          d="M20.4385 12.1356V8.27542C20.557 7.03014 21.2381 5.81257 23.0255 5.91414H35.6078"
          strokeWidth="1.91107"
        />
        <path
          d="M55.0889 4.9502V7.68288C55.1953 8.45145 55.8394 9.98859 57.5639 9.98859C59.2884 9.98859 66.6389 9.98859 70.0985 9.98859"
          strokeWidth="1.91613"
        />
        <path
          d="M73.1323 4.9502V11.7461C73.0044 12.5147 72.2301 14.0518 70.1571 14.0518C68.084 14.0518 59.2477 14.0518 55.0888 14.0518"
          strokeWidth="1.91613"
        />
      </svg>
    </Link>

  )
}

export default PoweredByBrsys
